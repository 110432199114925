const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        logout: baseUrl + 'auth/logout/',
        status: baseUrl + 'auth/status/',
        verify: baseUrl + 'mcq_interviews/verify-token/',

        userDashboard: baseUrl + 'auth/users/dashboard/',
        userCreate: baseUrl + 'auth/users/create/',
        userList: baseUrl + 'auth/users/list/',
        userDelete: baseUrl + 'auth/users/delete/',
        userDetails: baseUrl + 'auth/users/details/',
        userSelect: baseUrl + 'auth/users/vue-select/',
        user_role: {
            addEdit: baseUrl + 'auth/user-role/add-edit/',
            list: baseUrl + 'auth/user-role/list/',
            delete: baseUrl + 'auth/user-role/delete/'
        }
    },

    interview: {
        attend: baseUrl + 'interviews/attend/',

        create: baseUrl + 'interviews/create/',
        changeStatus: baseUrl + 'interviews/change-status/',
        list: baseUrl + 'interviews/list/',
        delete: baseUrl + 'interviews/delete/',
        details: baseUrl + 'interviews/details/',

        createTemplate: baseUrl + 'interviews/template-create/',
        listTemplate: baseUrl + 'interviews/template-list/',
        deleteTemplate: baseUrl + 'interviews/template-delete/',

        questions: baseUrl + 'interviews/questions/',
        answer: baseUrl + 'interviews/answer/',
        messages: baseUrl + 'interviews/messages/'
    },

    mcq_interview: {
        interview_type: {
            addEdit: baseUrl + 'mcq_interviews/interview-type-add-edit/',
            list: baseUrl + 'mcq_interviews/interview-type-list/',
            delete: baseUrl + 'mcq_interviews/interview-type-delete/',
            select: baseUrl + 'mcq_interviews/interview-type-select/'
        },
        interview_topic: {
            addEdit: baseUrl + 'mcq_interviews/interview-topic-add-edit/',
            list: baseUrl + 'mcq_interviews/interview-topic-list/',
            delete: baseUrl + 'mcq_interviews/interview-topic-delete/',
            select: baseUrl + 'mcq_interviews/interview-topic-vue-select/'
        },
        skill: {
            addEdit: baseUrl + 'mcq_interviews/skill-add-edit/',
            list: baseUrl + 'mcq_interviews/skill-list/',
            delete: baseUrl + 'mcq_interviews/skill-delete/',
            select: baseUrl + 'mcq_interviews/skill-vue-select/'
        },
        candidate: {
            addEdit: baseUrl + 'mcq_interviews/candidate-add-edit/',
            list: baseUrl + 'mcq_interviews/candidate-list/',
            delete: baseUrl + 'mcq_interviews/candidate-delete/',
            select: baseUrl + 'mcq_interviews/candidate-vue-select/',
            bulkupload: baseUrl + 'mcq_interviews/candidate-bulk-upload/'
        },
        interview_candidate: {
            addEdit: baseUrl + 'mcq_interviews/interview-candidate-add-edit/',
            list: baseUrl + 'mcq_interviews/interview-candidate-list/',
            delete: baseUrl + 'mcq_interviews/interview-candidate-delete/',
            select: baseUrl + 'mcq_interviews/interview-candidate-vue-select/',
            email: baseUrl + 'mcq_interviews/candidate-mail/'
        },
        interview: {
            addEdit: baseUrl + 'mcq_interviews/interview-add-edit/',
            list: baseUrl + 'mcq_interviews/interview-list/',
            delete: baseUrl + 'mcq_interviews/interview-delete/',
            select: baseUrl + 'mcq_interviews/interview-vue-select/',
            details: baseUrl + 'mcq_interviews/interview-details/',
            questionDetails: baseUrl + 'mcq_interviews/interview-questions-details/',
            candidateDetails: baseUrl + 'mcq_interviews/interview-candidates-details/',
            resultDetails: baseUrl + 'mcq_interviews/interview-result-details/',
            downloadResults: baseUrl + 'mcq_interviews/download-results/',
            generateQuestions: baseUrl + 'mcq_interviews/question/generate/',
            questionStatusChange: baseUrl + 'mcq_interviews/question/change-status/',
            mcqAttend: baseUrl + 'mcq_interviews/attend-interview/',
            submit: baseUrl + 'mcq_interviews/submit-interview/',
            testCustomPrompt: baseUrl + 'mcq_interviews/test-custom-prompt/'
        },
        interviewAllocation: {
            list: baseUrl + 'mcq_interviews/result-list/',
            create: baseUrl + 'mcq_interviews/interview-topic-allocation-add-edit/',
            delete: baseUrl + 'mcq_interviews/interview-topic-allocation-delete/',
            generateQuestions: baseUrl + 'mcq_interviews/question/generate/'
        },
        mcq_result: {
            list: baseUrl + 'mcq_interviews/result-list/',
            answers: baseUrl + 'mcq_interviews/show-answers/'
        },
        interview_sub_topic: {
            addEdit: baseUrl + 'mcq_interviews/interview-sub-topic-add-edit/',
            list: baseUrl + 'mcq_interviews/interview-sub-topic-list/',
            delete: baseUrl + 'mcq_interviews/interview-sub-topic-delete/',
            select: baseUrl + 'mcq_interviews/interview-sub-topic-vue-select/'
        }

    }
};
