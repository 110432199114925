import guards from './guards';

const AdminLayout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'MainLayout',
    redirect: '/app/',
    component: AdminLayout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/app/',
            name: 'HomePage',
            component: () => import(/* webpackChunkName: "home" */'../views/HomePage')
        },
        {
            path: '/interview/:id/details/',
            name: 'UserInterviewDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/common-components/InterviewDetailsPage')
        },
        {
            path: '/admin/interview-templates/',
            name: 'AdminInterviewTemplateListingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/interview-management/InterviewTemplateListing')
        },
        {
            path: '/admin/attended-interviews/',
            name: 'AdminAttendedInterviewListingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/interview-management/AttendedInterviewListing')
        },
        {
            path: '/admin/user-role/',
            name: 'AdminUserRole',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/user-role/UserRole')
        },
        {
            path: '/admin/mcq-interviews/',
            name: 'AdminMCQInterviewHome',
            component: () => import(/* webpackChunkName: "home" */'../views/mcq-interview/MCQInterviewHome')
        },
        {
            path: '/mcq-interview/interview-type/',
            name: 'InterviewType',
            component: () => import('../views/mcq-interview/InterviewType/InterviewType')
        },
        {
            path: '/mcq-interview/interview-topic/',
            name: 'InterviewTopic',
            component: () => import('../views/mcq-interview/InterviewTopic/InterviewTopic')
        },
        {
            path: '/mcq-interview/skill/',
            name: 'Skill',
            component: () => import('../views/mcq-interview/Skill/Skill')
        },
        {
            path: '/mcq-interview/candidate/',
            name: 'Skill',
            component: () => import('../views/mcq-interview/Candidate/Candidate')
        },
        {
            path: '/mcq-interview/interview/',
            name: 'Interview',
            component: () => import('../views/mcq-interview/Interview/Interview')
        },
        {
            path: '/mcq-interview/:id/interview-details/',
            name: 'InterviewDetails',
            component: () => import('../views/mcq-interview/Interview/InterviewDetails')
        },
        {
            path: '/mcq-interview/landing-page/',
            name: 'InterviewLandingPage',
            component: () => import('../views/mcq-interview/Interview/AttendInterviewHome')
        },

        {
            path: '/mcq-interview/interview_page/',
            name: 'MCQInterviewPage',
            component: () => import('../views/mcq-interview/Interview/InterviewSession')
        },
        {
            path: '/mcq-interview/submission-confirmation/',
            name: 'MCQInterviewSubmission',
            component: () => import('../views/mcq-interview/Interview/SubmissionConfirmation')
        },
        {
            path: '/mcq-interview/error-link/',
            name: 'MCQInterviewErrorLink',
            component: () => import('../views/mcq-interview/Interview/ErrorLink')
        },
        {
            path: '/user/interviews/',
            name: 'UserInterviewListingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/user/interview/InterviewListing')
        },
        {
            path: '/user/attended-interviews/',
            name: 'UserAttendedInterviewListingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/user/interview/AttendedInterviewListing')
        },
        {
            path: '/user/interview/:id/start/',
            name: 'UserInterview',
            component: () => import(/* webpackChunkName: "home" */'../views/user/interview/Interview')
        },
        {
            path: '/mcq-interview/interview-sub-topic/',
            name: 'InterviewSubTopic',
            component: () => import(/* webpackChunkName: "home" */'../views/mcq-interview/InterviewSubTopic/InterviewSubTopic')
        },
        {
            path: '/mcq-interview/test-custom-prompt/',
            name: 'TestCustomPrompt',
            component: () => import(/* webpackChunkName: "home" */'../views/mcq-interview/Interview/TestCustomPrompt')
        }
    ]
};
