export default {
    state: {
        currentUser: {},
        checkedStatus: false,
        needChecking: true,
        candidate: '',
        interview: ''
    },

    getters: {
        currentUser: state => state.currentUser,
        checkedStatus: state => state.checkedStatus,
        needChecking: state => state.needChecking,
        interview: state => state.interview,
        candidate: state => state.candidate
    },

    mutations: {
        setUser (state, payload) {
            console.log('payload', payload);
            state.currentUser = payload;
            state.checkedStatus = true;
        },
        setInterview (state, payload) {
            console.log('payload', payload);
            console.log('state', state);
            state.candidate = payload.candidate;
            state.interview = payload.interview;
        },

        setLogout (state) {
            console.log('setLogout', state);
            state.currentUser = {};
            state.checkedStatus = true;
        }
    },

    actions: {
        setUser ({ commit }, payload) {
            commit('setUser', payload);
        },
        setInterview ({ commit }, payload) {
            commit('setInterview', payload);
        },

        signOut ({ commit }) {
            // Perform signOut
            commit('setLogout');
        }
    }
};
